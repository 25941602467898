'use client';

import { DEBUG } from '@/lib/cfg';
import { Button } from '@nextui-org/button';
import { useEffect } from 'react';

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  useEffect(() => {
    // Log the error to an error reporting service
    DEBUG && console.error('AUTH ERROR', error);
  }, [error]);

  return (
    <div className="w-full mx-auto text-center mt-10">
      <h2>Error</h2>
      {error && <p>{error.message}</p>}
      <Button

        className="mt-10"
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </Button>
    </div>
  );
}
