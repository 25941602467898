/**
 * Local CFG
 *
 * Since Next requires public configs to be injected during BUILD, To add a new NEXT_PUBLIC_* config:
 * 1) Add the config value to the Cloud Build settings for the trigger
 * 2) add build-arg to cloudbuild.yaml like `--build-arg 'NEXT_PUBLIC_MIXPANEL_TOKEN=${_MIXPANEL_TOKEN}'`
 * 3) Add the ARG and ENV to Dockerfile like
 *     ARG NEXT_PUBLIC_MIXPANEL_TOKEN
 *     ENV NEXT_PUBLIC_MIXPANEL_TOKEN=$NEXT_PUBLIC_MIXPANEL_TOKEN
 */

export const DEBUG: boolean = process.env.NEXT_PUBLIC_DEBUG === 'true' ? true : false;

export const COOKIE_NAME = 'ugs-admin';

export const API_HOST = process.env.API_HOST || 'http://127.0.0.1:6773';

// Hostname of production environment
export const PRODUCTION_HOST = process.env.PRODUCTION_HOST || 'admin.giftedsavings.org';

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || '';

export const FULLSTORY_API_KEY = process.env.FULLSTORY_API_KEY || '';

export const MUX_SIGNING_ID = process.env.MUX_SIGNING_ID;

export const MUX_SIGNING_PRIVATE_KEY = process.env.MUX_SIGNING_PRIVATE_KEY;

export const MUX_TOKEN_ID = process.env.MUX_TOKEN_ID || '';

export const MUX_TOKEN_SECRET = process.env.MUX_TOKEN_SECRET || '';
